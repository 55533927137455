import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import moment from 'moment';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { useSelector } from 'react-redux';
import { getAvailableTime, scheduleAppointment, updateAppoinment, getTourTime, scheduleTourTime, updateTourTime } from '../Api/ListingApi';
import 'react-toastify/dist/ReactToastify.css';
import Moment from "moment-timezone";
import ToastPopup from './ToastPopup';

export default function BuyerScheduleCalender(props) {
    const { click, closeTour, toggleClick, details, propertyId, isBuyer, detail, reload, refresh } = props;
    const [dateState, setDateState] = useState(null)
    const [timeList, setTimeList] = useState([])
    // console.log("timeList", timeList)
    const [selectIndex, setSelectIndex] = useState(null)
    const [success, setSucess] = useState(false);
    const [messages, setMessages] = useState("")
    const userDetails = useSelector(state => state.user.userDetail);
    const DateFormat = 'YYYY-MM-DD'
    // console.log("date format", DateFormat)
    const toggleSuccess = () => {
        setSucess(p => !p);
    }
    useEffect(() => {
        changeDate(moment().format())
        if (isBuyer === false) {
            let date = new Date()
            let value = {
                year: JSON.parse(moment(date).format('YYYY')),
                month: JSON.parse(moment(date).format('M')),
                day: JSON.parse(moment(date).format('D'))
            }
            setDateState(value)
            var today = moment(new Date()).format(DateFormat)
            handleGetAppoinmentList(today)
        }
        else {
            if (Object.keys(details.data !== undefined && details.data).length !== 0) {
                let date = new Date()
                let value = {
                    year: JSON.parse(moment(date).format('YYYY')),
                    month: JSON.parse(moment(date).format('M')),
                    day: JSON.parse(moment(date).format('D'))
                }
                setDateState(value)
                var today = moment(new Date()).format(DateFormat)
                handleGetAppoinmentList(today)
            }
        }
    }, [details])

    const handleGetAppoinmentList = (today) => {
        if (isBuyer === false) {
            var formData = {
                appointment_date: today,
                staff_id: detail !== undefined && detail.staff_id
            }
        }
        else {
            var formData = {
                appointment_date: today,
                staff_id: detail.list === "listingAgent" && detail.staff_id !== null ? detail.staff_id : detail.staff_id !== null ? detail.staff_id : 0
            }
        }
        // console.log("formData", formData)
        getTourTime(formData).then((res) => {
            // console.log("res resres", res)
            setTimeList(res.data.data.result)
        }).catch((error) => {
            console.log(error)
        })
    }
    const changeDate = (date) => {
        var today = moment(date).format(DateFormat)
        handleGetAppoinmentList(today)
    }
    const handleSelect = (time) => {
        setSelectIndex(time)
    }
    const handleSubmit = async () => {
        if (selectIndex !== "") {
            if (isBuyer === false) {
                let id = props.details.appointment_id;
                if (detail.buyer_agent_id && (detail.buyer_agent_id._id === userDetails._id)) {
                    var getPrams = {
                        date_time: selectIndex,
                        property_id: detail.property_id._id,
                        is_updated_by_buyer_agent: detail.buyer_agent_id._id
                    }
                } else {
                    if (userDetails.role === "user") {
                        var getPrams = {
                            date_time: selectIndex,
                            property_id: detail.property_id._id,
                            is_updated_by: detail.user_id._id
                        }

                    } else {
                        if (detail.agent_id._id) {
                            var getPrams = {
                                date_time: selectIndex,
                                property_id: detail.property_id._id,
                                is_updated_by: detail.agent_id._id
                            }
                        } else {
                            var getPrams = {
                                date_time: selectIndex,
                                property_id: detail.property_id._id,
                                is_updated_by: detail.agent_id._id
                            }
                        }
                    }
                }
                await updateTourTime(id, getPrams).then((res) => {
                    setMessages("Your tour has changed")
                    toggleSuccess()
                    setTimeout(() => toggleSuccess(), 3000)
                    setTimeout(() => toggleClick(), 3000)
                    refresh()
                    reload()
                }).catch((error) => {
                    console.log(error)
                })
            }
            else {
                if (userDetails.role === "agent") {
                    var data = {
                        "date_time": selectIndex,
                        "staff_id": detail.list === "listingAgent" ? detail.staff_id : (detail.staff_id !== undefined && detail.staff_id),
                        "name": details.data.name,
                        "email": details.data.email,
                        "property_id": propertyId,
                        "agent_id": details.list === "listingAgent" ? details.data._id : details.data.user_id._id,
                        "buyer_agent_id": userDetails._id,

                    }
                } else {
                    var data = {
                        "date_time": selectIndex,
                        "staff_id": detail.list === "listingAgent" ? detail.staff_id : (detail.staff_id !== undefined && detail.staff_id),
                        "name": details.data.name,
                        "email": details.data.email,
                        "property_id": propertyId,
                        "agent_id": details.list === "listingAgent" ? details.data._id : details.data.user_id._id,
                        "user_id": userDetails._id,

                    }
                }
                await scheduleTourTime(data).then((res) => {
                    setMessages("Success! Your tour is scheduled")
                    toggleSuccess()
                    setTimeout(() => toggleSuccess(), 3000)
                    setTimeout(() => closeTour(), 3000)
                    setTimeout(() => toggleClick(), 3000)
                    refresh()
                }).catch((error) => {
                    console.log(error)
                })
            }
        }
    }
    const handlechange = (date) => {
        setDateState(date)
        var select = moment(`${date.year}-${date.month}-${date.day}`).format()
        changeDate(select)
    }
   
    return (
        <div>
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            <Modal show={click} onHide={toggleClick} className="special_modal ModalDiv" dialogClassName="my-modal" >
                <div className="PopUpBorderDiv">
                    <Modal.Header closeButton className="calendar-popup-margin teampopup-title" >
                        <Modal.Title className='form-field-label form-label-team' style={{ fontFamily: "DejaVuSansBold" }}>Pick a Date to Tour</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="team-popup-margin pb-0" id="team-popup" >
                        <div className="tourAvailiablePopUp">
                            <Calendar
                                value={dateState}
                                onChange={(date) => handlechange(date)}
                                className={style1.Calendar}
                                minimumDate={utils().getToday()} />
                            {/* {console.log("timeList", timeList)} */}
                            {timeList !== undefined && timeList.length !== 0 ?
                                <>
                                    <p className='mt-3 ' style={{ fontFamily: "DejaVuSansBold" ,marginLeft:"1rem"}}>Select a Time</p>
                                    <div className='d-flex w-100 flex-wrap'>
                                        {timeList.map((item, index) => (
                                            <div className={`${selectIndex === item ? 'scheduleTimeContainerActive cursor-pointer' : "scheduleTimeContainer cursor-pointer"}`} key={index} onClick={() => handleSelect(item)} aria-hidden>
                                                <p className={`${selectIndex === item ? 'scheduleTimeActive' : 'scheduleTime'}`} >
                                                    {/* {(Moment(item).tz('US/Pacific').format('hh:mm A'))}</p> */}
                                                    {/* {(Moment(item).format('hh:mm A'))}</p> */}
                                                    {(Moment(item).format('hh:mm A'))}</p>

                                            </div>
                                        ))}
                                    </div>
                                </>
                                : <p className='my-3 text-center'>There are no available slots on this date. Please select another date to view available slots.</p>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="team-popup-margin teampop-footer" >
                        <Button variant="primary"  disabled={selectIndex==null?true:false}  className='teamnext-submit-btn row col-lg-12 col-xl-12 col-md-12' onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </div>
    )
}
